import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

function NearByInfo({ nearByInfo }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0)
  const [eductionList, setEducationList] = useState([])
  const [healthList, setHealthList] = useState([])
  const [transportationList, setTransportationList] = useState([])
  // Sort the data by distance and then by rating
  const sortByDistanceAndRating = (a, b) => {
    const distanceComparison = parseFloat(a.distance) - parseFloat(b.distance);
    if (distanceComparison !== 0) {
      return distanceComparison; // Sort by distance
    } else {
      return b.rating - a.rating; // If distance is same, sort by rating descending
    }
  };
  useEffect(() => {
    let educationData = [];
    let healthData = [];
    let transportationData = [];
    nearByInfo?.forEach(item => {
      if (["school", "primary_school", "secondary_school"].includes(item?.type)) {
        const currentData = item.data.results?.map((element) => ({ name: element?.name, rating: element?.rating, distance: parseFloat(element?.distanceInMeter).toFixed(2), type: item?.type }))
        educationData.push(...currentData);
      } else if (["hospital", "pharmacy", "drugstore"].includes(item?.type)) {
        const currentData = item.data.results?.map((element) => ({ name: element?.name, rating: element?.rating, distance: parseFloat(element?.distanceInMeter).toFixed(2), type: item?.type }))
        healthData.push(...currentData);
      } else if (["bus_station", "gas_station", "parking"].includes(item?.type)) {
        const currentData = item.data.results?.map((element) => ({ name: element?.name, rating: element?.rating, distance: parseFloat(element?.distanceInMeter).toFixed(2), type: item?.type }))
        transportationData.push(...currentData);
      }
    });
    // Sort the data by distance
    // educationData.sort(sortByDistanceAndRating);
    // healthData.sort(sortByDistanceAndRating);
    // transportationData.sort(sortByDistanceAndRating);
    setEducationList(educationData);
    setHealthList(healthData);
    setTransportationList(transportationData);
  }, [nearByInfo])


  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <h4 className="title fz17 mb30">
        <i className="fal fa-map-pin me-2" />
        {t("WHATS NEARBY")}
      </h4>
      <div className="row">
        <div className="col-md-12">
          <div className="navtab-style1 tabView " >
            <TabView
              className="pr_tabView"
              activeIndex={activeTab}
              onTabChange={(e) => {
                setActiveTab(e?.index);
              }}
            >
              <TabPanel
                header={t("EDUCATION")}
                headerClassName="pr_tabHeader me-2"
              >
                <div className='nearByTabPanel'>
                  {
                    eductionList && eductionList?.length > 0 ?
                      eductionList?.map((place ,index) => {

                        const distanceInKm = parseInt(parseInt(place?.distance) / 1000)
                        return <div key={index + 1} className="nearby d-sm-flex align-items-center mb20">
                          <div className="details">
                            <p className="dark-color fw600 mb-0">
                              {place?.name}
                            </p>
                            <p className="text mb-0">
                            {t("GRADES")}:   {place?.rating} | {t("DISTANCE")}: {distanceInKm} KM
                            </p>
                          </div>
                        </div>
                      }) : ""
                  }
                </div>
              </TabPanel>
              <TabPanel
                header={t("HEALTH AND MEDICAL")}
                headerClassName="pr_tabHeader me-2"
              >
                <div className='nearByTabPanel'>
                  {
                    healthList && healthList?.length > 0 ?
                      healthList?.map((place , index) => {
                        const distanceInKm = parseInt(parseInt(place?.distance) / 1000)
                        return <div key={index + 1} className="nearby d-sm-flex align-items-center mb20">
                          <div className="details">
                            <p className="dark-color fw600 mb-0">
                              {place?.name}
                            </p>
                            <p className="text mb-0">
                              {t("GRADES")}:   {place?.rating} | {t("DISTANCE")}: {distanceInKm} KM
                            </p>
                          </div>
                        </div>
                      }) : ""
                  }
                </div>
              </TabPanel>
              <TabPanel
                header={t("TRANSPORTATION")}
                headerClassName="pr_tabHeader me-2"
              >
                <div className='nearByTabPanel'>
                  {
                    transportationList && transportationList?.length > 0 ?
                      transportationList?.map((place , index) => {
                        const distanceInKm = parseInt(parseInt(place?.distance) / 1000)
                        return <div key={index + 1} className="nearby d-sm-flex align-items-center mb20">
                          <div className="details">
                            <p className="dark-color fw600 mb-0">
                              {place?.name}
                            </p>
                            <p className="text mb-0">
                            {t("GRADES")}:   {place?.rating} | {t("DISTANCE")}: {distanceInKm} KM
                            </p>
                          </div>
                        </div>
                      }) : ""
                  }
                </div>
              </TabPanel>
            </TabView>

          </div>
        </div>
      </div>
    </div>
  )
}

export default NearByInfo
